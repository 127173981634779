define("ember-data-model-fragments/transforms/fragment", ["exports", "@ember/debug", "@ember-data/serializer/transform", "@ember-data/serializer/json-api", "@ember/service"], function (_exports, _debug, _transform, _jsonApi, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /**
    @module ember-data-model-fragments
  */

  /**
    Transform for `MF.fragment` fragment attribute which delegates work to
    the fragment type's serializer
  
    @class FragmentTransform
    @namespace MF
    @extends DS.Transform
  */
  // eslint-disable-next-line ember/no-classic-classes
  const FragmentTransform = _transform.default.extend({
    store: (0, _service.inject)(),
    type: null,
    polymorphicTypeProp: null,
    deserialize: function deserializeFragment(data, options, parentData) {
      if (data == null) {
        return null;
      }
      return this.deserializeSingle(data, options, parentData);
    },
    serialize: function serializeFragment(snapshot) {
      if (!snapshot) {
        return null;
      }
      const store = this.store;
      const realSnapshot = snapshot._createSnapshot ? snapshot._createSnapshot() : snapshot;
      const serializer = store.serializerFor(realSnapshot.modelName || realSnapshot.constructor.modelName);
      return serializer.serialize(realSnapshot);
    },
    modelNameFor(data, options, parentData) {
      let modelName = this.type;
      const polymorphicTypeProp = this.polymorphicTypeProp;
      if (data && polymorphicTypeProp && data[polymorphicTypeProp]) {
        modelName = data[polymorphicTypeProp];
      } else if (options && typeof options.typeKey === 'function') {
        modelName = options.typeKey(data, parentData);
      }
      return modelName;
    },
    deserializeSingle(data, options, parentData) {
      var _serialized$data;
      const store = this.store;
      const modelName = this.modelNameFor(data, options, parentData);
      const serializer = store.serializerFor(modelName);
      (false && !(!(serializer instanceof _jsonApi.default)) && (0, _debug.assert)('The `JSONAPISerializer` is not suitable for model fragments, please use `JSONSerializer`', !(serializer instanceof _jsonApi.default)));
      const typeClass = store.modelFor(modelName);
      const serialized = serializer.normalize(typeClass, data);

      // `JSONSerializer#normalize` returns a full JSON API document, but we only
      // need the attributes hash
      return serialized === null || serialized === void 0 ? void 0 : (_serialized$data = serialized.data) === null || _serialized$data === void 0 ? void 0 : _serialized$data.attributes;
    }
  });
  var _default = _exports.default = FragmentTransform;
});