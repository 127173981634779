define("ember-router-service-refresh-polyfill/initializers/setup-router-service-refresh-polyfill", ["exports", "@ember/debug", "@ember/application"], function (_exports, _debug, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.SHOULD_POLYFILL_ROUTER_SERVICE_REFRESH = void 0;
  _exports.initialize = initialize;
  /* eslint-disable ember/no-private-routing-service */

  const SHOULD_POLYFILL_ROUTER_SERVICE_REFRESH = _exports.SHOULD_POLYFILL_ROUTER_SERVICE_REFRESH = !true;
  function initialize(application) {}
  var _default = _exports.default = {
    initialize
  };
});