define("ember-data-model-fragments/array/fragment", ["exports", "@ember/debug", "@ember/utils", "ember-data-model-fragments/array/stateful", "ember-data-model-fragments/fragment", "ember-data-model-fragments/util/instance-of-type", "@ember-data/store/-private"], function (_exports, _debug, _utils, _stateful, _fragment, _instanceOfType, _private) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /**
    @module ember-data-model-fragments
  */

  /**
    A state-aware array of fragments that is tied to an attribute of a `DS.Model`
    instance. `FragmentArray` instances should not be created directly, instead
    use `MF.fragmentArray` or `MF.array`.
  
    @class FragmentArray
    @namespace MF
    @extends StatefulArray
  */
  const FragmentArray = _stateful.default.extend({
    /**
      The type of fragments the array contains
       @property modelName
      @private
      @type {String}
    */
    modelName: null,
    _normalizeData(data, index) {
      (false && !((0, _utils.typeOf)(data) === 'object' || (0, _instanceOfType.default)(this.store.modelFor(this.modelName), data)) && (0, _debug.assert)(`You can only add '${this.modelName}' fragments or object literals to this property`, (0, _utils.typeOf)(data) === 'object' || (0, _instanceOfType.default)(this.store.modelFor(this.modelName), data)));
      if ((0, _fragment.isFragment)(data)) {
        const recordData = (0, _private.recordDataFor)(data);
        (0, _fragment.setFragmentOwner)(data, this.recordData, this.key);
        return recordData._fragmentGetRecord();
      }
      const existing = this.currentState[index];
      if (existing) {
        existing.setProperties(data);
        return existing;
      }
      const recordData = this.recordData._newFragmentRecordDataForKey(this.key, data);
      return recordData._fragmentGetRecord();
    },
    _getFragmentState() {
      const recordDatas = this._super();
      return recordDatas === null || recordDatas === void 0 ? void 0 : recordDatas.map(recordData => recordData._fragmentGetRecord());
    },
    _setFragmentState(fragments) {
      const recordDatas = fragments.map(fragment => (0, _private.recordDataFor)(fragment));
      this._super(recordDatas);
    },
    /**
      @method _createSnapshot
      @private
    */
    _createSnapshot() {
      // Snapshot each fragment
      return this.map(fragment => {
        return fragment._createSnapshot();
      });
    },
    /**
      If this property is `true`, either the contents of the array do not match
      its original state, or one or more of the fragments in the array are dirty.
       Example
       ```javascript
      array.toArray(); // [ <Fragment:1>, <Fragment:2> ]
      array.get('hasDirtyAttributes'); // false
      array.get('firstObject').set('prop', 'newValue');
      array.get('hasDirtyAttributes'); // true
      ```
       @property hasDirtyAttributes
      @type {Boolean}
      @readOnly
    */

    /**
      This method reverts local changes of the array's contents to its original
      state, and calls `rollbackAttributes` on each fragment.
       Example
       ```javascript
      array.get('firstObject').get('hasDirtyAttributes'); // true
      array.get('hasDirtyAttributes'); // true
      array.rollbackAttributes();
      array.get('firstObject').get('hasDirtyAttributes'); // false
      array.get('hasDirtyAttributes'); // false
      ```
       @method rollbackAttributes
    */

    /**
      Serializing a fragment array returns a new array containing the results of
      calling `serialize` on each fragment in the array.
       @method serialize
      @return {Array}
    */
    serialize() {
      return this.invoke('serialize');
    },
    /**
      Adds an existing fragment to the end of the fragment array. Alias for
      `addObject`.
       @method addFragment
      @param {MF.Fragment} fragment
      @return {MF.Fragment} the newly added fragment
    */
    addFragment(fragment) {
      return this.addObject(fragment);
    },
    /**
      Removes the given fragment from the array. Alias for `removeObject`.
       @method removeFragment
      @param {MF.Fragment} fragment
      @return {MF.Fragment} the removed fragment
    */
    removeFragment(fragment) {
      return this.removeObject(fragment);
    },
    /**
      Creates a new fragment of the fragment array's type and adds it to the end
      of the fragment array.
       @method createFragment
      @param {MF.Fragment} fragment
      @return {MF.Fragment} the newly added fragment
      */
    createFragment(props) {
      const recordData = this.recordData._newFragmentRecordDataForKey(this.key, props);
      const fragment = recordData._fragmentGetRecord(props);
      return this.pushObject(fragment);
    }
  });
  var _default = _exports.default = FragmentArray;
});